<template>
	<div class="menu-header">
		<!--    LOGO-->
		<router-link tag="div" to="/" class="logo-wrapper center">
			<img src="@/assets/image/logo_color.png" alt="LOGO_WHITE" style="width: 100px;height: 40px;" />
		</router-link>
		<!--    导航-->
		<div class="menu-wrapper">
			<div class="menu-item clickable center" v-for="menu in menuList" @click="handleMenuClick(menu)">
				<div class="menu-item-name">{{ $t(menu.name) }}</div>

				<div class="submenu-list" v-if="menu.children && menu.children.length > 0">
					<div class="submenu-item clickable" v-for="submenu in menu.children"
						@click.stop="handleMenuClick(submenu)">
						<div class="submenu-item-name">{{ $t(submenu.name) }}</div>
					</div>
				</div>
			</div>
		</div>
		<!--    右侧操作按钮-->
		<div class="actions-wrapper">
			<div class="icon clickable center" style="width: 85px" @click="handleLangClick">
				<span>{{ $t("lang") }}</span>
				<img class="lang" src="@/assets/image/icon/icon_language_b.png" alt="切换语言" />
			</div>
			<div class="icon clickable center">
				<img src="@/assets/image/icon/icon_message_b.png" alt="通知" />
			</div>
			<div class="icon clickable center">
				<span style="margin-right: 10px;" v-if="userId">

					<router-link to="/accout" style="color: #2b2b2b;
;">{{ $t("newadd.t.用户") }} ID:{{userId}}</router-link>
				</span>
			</div>
			<!--      注册登录 或者 会员中心-->
			<div class="clickable login center">

				<span @click="handleLoginRegisterClick" v-if="!Authorization">{{
            $t("headerMenu.SignInOut")
          }}</span>

				<span @click="logout" v-else> {{ $t("headerMenu.SignBack") }}</span>


			</div>
		</div>
	</div>
</template>

<script>
	import {
		setupLanguage
	} from "@/plugins/i18n";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		getToken,
		removeToken
	} from "@/utils/auth";
	import {
		getUserInfo
	} from "@/api/User";

	export default {
		data() {
			return {
				menuList: [{
						name: "newadd.t.首页",
						path: "/main",

					},
					{
						name: "headerMenu.otherMall",
						path: "/mall",
						query: {
							type: 1
						}
					},
					{
						name: "headerMenu.orderManage",
						path: "/orderadmin",
					},
					{
						name: "headerMenu.vip",
						path: "/vipCenter",
					},
					{
						name: "newadd.t.酷灵猫",
						path: "/article",
						status:'net'
					},


				],
				Authorization: getToken(),
				userId: ''
			};
		},
		mounted() {
			if (this.Authorization) {
				getUserInfo().then(res => {
					this.userId = res.id;
					this.$store.commit('SET_UERINFO', res)
				}).catch(err => {

				})
			}
		},
		methods: {

			// 切换语言
			handleLangClick() {
				let lang = "zh-cn";
				if (this.$i18n.locale === "zh-cn") lang = "en-us";
				setupLanguage(lang);
			},
			// 菜单点击
			handleMenuClick(menu) {
				let {
					path,
					query,
					status
				} = menu;
				if (path) {
					if(status=='net'){
						if(this.$i18n.locale=='en-us'){
							this.$router.push({
								path:"/website?id=18",
							})
						}else{
							this.$router.push({
								path:"/website?id=17",
							})
						}
						return
					}
					
					this.$router.push({
						path,
						query
					})
					if(path=='/mall'){
						setTimeout(()=>{
							location.reload()
						},200)
						
					}
				}
				
			},
			// 登录
			handleLoginRegisterClick() {
				this.$router.push({
					path: "/login"
				});
			},
			// 退出登录
			logout() {
				removeToken();
			},
		},
	};
</script>

<style scoped lang='scss'>
	$header-bg: rgba(219, 219, 219, 0.6);//#3296fa;//#2a2a29;

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.menu-header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		background-color: $header-bg;
		height: 44px;
		display: flex;
		color: #2b2b2b;//#fdfdfd;
		user-select: none;
		font-size: 16px;
		z-index: 200;
		opacity: 0.8;
		padding-left: 20px;

		.clickable {
			cursor: pointer;
			white-space: nowrap;

			&:hover {
				background-color: rgba(160, 160, 160, 0.1);
			}
		}

		.logo-wrapper {
			margin-right: 100px;
			cursor: pointer;
		}

		.menu-wrapper {
			flex: 1;
			display: flex;
			align-items: center;

			.menu-item {
				padding: 0 20px;
				position: relative;
				height: 100%;

				&:hover {
					.submenu-list {
						display: block;
					}
				}

				.submenu-list {
					display: none;
					position: absolute;
					top: 100%;
					width: auto;
					background-color: $header-bg;
					font-size: 12px;

					.submenu-item {
						padding: 10px 20px;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						text-wrap: none;
					}
				}
			}
		}

		.actions-wrapper {
			display: flex;

			.icon {
				height: 100%;
				padding: 0 20px;

				.lang {
					font-size: 14px;
					margin-left: 5px;
				}
			}

			.login {
				height: 100%;
				padding: 0 20px;
				position: relative;

				&:hover .sign-out {
					display: block;
				}

				.sign-out {
					position: absolute;
					top: 100%;
					background-color: $header-bg;
					width: 100%;
					padding: 10px 20px;
					display: none;
				}
			}
		}
	}
</style>
