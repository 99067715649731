<template>
  <div class="main" :style="`min-width: ${minPageWidth}px`">
    <menu-header :style="`min-width: ${minPageWidth}px`"/>
    <div class="main-wrapper" :style="`min-width: ${minPageWidth}px`">
      <transition mode="out-in" name="fade-in-out">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import MenuHeader from "@/components/MenuHeader";
import constant from "@/utils/constant"

export default {
  components: {MenuHeader},
  data() {
    return {
      minPageWidth: constant.minPageWidth
    }
  },
}
</script>

<style scoped lang='scss'>
   
</style>